.jobs-and-interns-discover {
  display: grid;
  grid-template-areas: "card header" "card description" "card description";
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  margin: 0 0 var(--section-margin);

  &__card-wrapper {
    align-items: center;
    display: flex;
    grid-area: card;
    justify-content: flex-start;
  }

  &__header {
    grid-area: header;

    @media (--screen-lg-max) {
      margin-bottom: var(--spacing) !important;
    }

    @media (--screen-md-max) {
      margin-bottom: 0 !important;
    }
  }

  &__description {
    grid-area: description;
  }

  &__card {
    align-items: center;
    background-color: var(--grey-100);
    border-radius: var(--box-border-radius-s);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem var(--spacing-xl);
    text-align: center;
    width: 90%;

    @media (--screen-md-max) {
      margin: var(--spacing) 0;
      width: 100%;
    }
  }

  &__card-stats {
    font-weight: var(--font-bold);

    &:first-of-type {
      border-bottom: 1px solid var(--border-grey-2);
      margin-bottom: var(--spacing);
      padding-bottom: var(--spacing);
    }
  }

  &__card-stat-number {
    display: flex;
    font-size: 3rem;
    height: 3rem;
    justify-content: center;
    line-height: 3rem;
    width: 20rem;

    a {
      color: var(--grey-600);
      display: block;
      overflow: hidden;
      position: relative;
      transition:
        transform 0.3s ease-out,
        color 0.3s ease-out;
      width: 100%;

      &:hover {
        color: var(--bn-orange);
        transform: scale(1.1);
      }
    }
  }

  &__sprite-column {
    display: flex;
    flex-direction: column;
    position: absolute;
  }

  &__card-stat-label {
    font-size: var(--font-sm);
    margin-top: var(--spacing-xxs);
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
  }

  &__link,
  &__link-arrow {
    align-items: center;
    display: flex;
  }

  &__link {
    margin-right: var(--spacing);

    @media (--screen-xs) {
      margin-top: var(--spacing);
    }
  }

  &__link-arrow {
    margin-right: var(--spacing-xxs);
  }

  @media (--screen-md-max) {
    margin-top: 0;
  }

  @media (--screen-md-max) {
    grid-template-areas: "header header" "card card" "description description";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.section-margin-top {
  margin-top: var(--section-margin);
}
