@import "swiper/swiper-bundle.css";
@import "../shared/components/bn-card.css";

.success-stories {
  margin: 1rem 0 var(--section-margin-s);
  opacity: 1;
  overflow: hidden;
  width: 100%;

  a {
    padding-top: 15px;

    @media (--screen-md) {
      padding-top: 0;
      text-align: center;
    }
  }

  .container {
    overflow: visible !important;
  }

  .bn-card__img {
    grid-column: span 5;
    height: 100%;

    @media (--screen-md) {
      border-bottom-right-radius: var(--box-border-radius);
      grid-column: span 4;
      height: 100px;
      overflow: hidden;
    }
  }

  .bn-card__body {
    grid-column: span 7;
    max-height: 300px;

    @media (--screen-md) {
      grid-column: span 12;
    }
  }

  .bn-card__header {
    font-size: var(--font-lg);
    padding: var(--spacing) 0 0 0;

    @media (--screen-md) {
      font-size: var(--font-sm);
      padding: 0;
    }
  }

  .bn-card__text {
    height: 200px;
    overflow: hidden;
    position: relative;

    &::after {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0%),
        rgba(255, 255, 255, 100%) 90%
      );
      bottom: 0;
      content: "";
      height: 4rem;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    @media (--screen-md) {
      font-size: var(--font-xs);
      height: 150px;
      padding: 0;
    }
  }

  .bn-card {
    align-content: flex-start;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    justify-items: center;
  }

  &__header {
    font-size: var(--font-xlg);
    font-weight: var(--font-bold);
    margin-bottom: 0;

    @media (--screen-md) {
      font-size: var(--font-sm);
    }
  }

  &__container {
    padding-top: var(--spacing-l);

    @media (--screen-md) {
      padding-bottom: var(--spacing-l);
    }
  }

  .swiper__container {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .swiper-slide {
    margin-bottom: var(--spacing-xxs);
    width: 700px;

    @media (--screen-md) {
      padding-top: 0;
      width: 300px;
    }
  }

  .centered {
    text-align: center;
  }

  .swiper-container {
    height: 100%;
    overflow: visible !important;
  }

  &__person-icon {
    margin: 0 var(--spacing-xxs) 3px 0;
  }

  &__person {
    overflow: hidden;
    padding: var(--spacing) 0 0 var(--spacing-l);

    &__mobile {
      display: none;
      font-size: var(--font-xs);
      grid-column-start: 5;
      max-height: 100px;
      padding: var(--spacing) var(--spacing) 0 var(--spacing);
      width: 100%;

      &__name {
        display: block;
        font-weight: var(--font-bold);
      }

      &__jobtype {
        display: block;
      }

      @media (--screen-md) {
        display: unset;
        grid-column: span 8;
      }
    }

    &__name {
      font-weight: var(--font-bold);
    }

    &__jobtype {
      display: block;
      padding-left: 19px;
    }

    @media (--screen-md) {
      display: none;
    }
  }
}
