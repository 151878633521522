.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-l);
  justify-content: center;
  margin: var(--section-margin-s) 0;

  .btn-social {
    align-items: center;
    border: 0 !important;
    border-radius: 25px;
    color: white;
    display: flex;
    fill: white;
    justify-content: flex-start;
    padding: 0 10px 0 0;
    transition: all 0.2s ease-out;

    &:hover {
      box-shadow: var(--box-shadow);
      transform: scale(1.1);
    }

    &__icon {
      align-items: center;
      border-radius: 25px;
      display: flex;
      height: 50px;
      justify-content: center;
      padding: 10px;
      width: 50px;

      svg {
        height: 80%;
        width: 80%;
      }
    }

    &__text {
      margin: 0 var(--spacing);
      text-align: center;
      vertical-align: middle;
    }

    &__linkedin {
      background-color: #1581c3;

      .btn-social__icon {
        background-color: #0072b1;
      }
    }

    &__facebook {
      background-color: #294d97;

      .btn-social__icon {
        background-color: #3b5998;
      }
    }

    &__instagram {
      background-color: #712f9d;

      .btn-social__icon {
        background-color: #833ab4;
      }
    }

    &__xing {
      background-color: #c6f16d;
      color: var(--text);

      .btn-social__icon {
        background-color: #93b748;
      }
    }
  }

  @media (--screen-xs) {
    gap: var(--spacing-s);

    .btn-social {
      width: 100%;
    }
  }
}
