@import "../../shared/components/bn-card.css";

.homepage-cta {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: var(--section-margin-s) 0;

  &__header {
    font-size: 1.8rem;
    font-weight: var(--font-bold);
    margin-bottom: var(--spacing-xxs);

    @media (--screen-md-max) {
      font-size: 1.4rem;
    }
  }

  &__image-wrapper {
    grid-column: 2;
    grid-row: 1/3;

    @media (--screen-md) {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;

    @media (--screen-md-max) {
      object-fit: contain;
    }
  }

  &__btn-container {
    margin: var(--spacing) 0;

    @media (--screen-md) {
      margin-bottom: 0;
    }
  }

  @media (--screen-md-max) {
    grid-template-columns: 1fr;
    grid-template-rows: 200px 1fr;
  }
}
