.bubble__wrap {
  height: 700px;
  overflow: hidden;

  @media (--screen-md) {
    height: 500px;
  }

  @media (--screen-xs) {
    height: 400px;
  }
}

.bubbles {
  height: 100%;
  position: relative;
  width: 100%;
}

.bubble__container {
  display: inline-block;
  height: 140px;
  opacity: 0;
  position: absolute;
  transform-origin: center;
  transition: opacity 0.2s ease-in-out;
  width: 140px;

  &.fadeIn {
    opacity: 1;
  }

  @media (--screen-md) {
    height: 100px;
    width: 100px;
  }

  @media (--screen-xs) {
    height: 75px;
    width: 75px;
  }
}

.bubble {
  background-color: white;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  height: 100%;
  transition: transform 0.2s ease-in;
  width: 100%;

  &:hover {
    box-shadow: var(--box-shadow);
    transform: scale(1.1);
  }
}

.bubble__image {
  height: 100%;
  object-fit: contain;
  padding: 1.5rem;
  width: 100%;

  @media (--screen-md) {
    padding: 1rem;
  }

  @media (--screen-xs) {
    padding: 0.75rem;
  }
}
