@import "swiper/swiper-bundle.css";
@import "./bubbles.css";
@import "../success_stories.css";
@import "./homepage_cta.css";
@import "./socials.css";
@import "./jobs_and_interns.css";
@import "./member_journey.css";
@import "./hero.css";
@import "./employers.css";
@import "./page-preview.css";

:root {
  --section-margin: 8rem;
  --section-margin-s: 5rem;
  --height-100: calc(100vh - 132px);

  @media (--screen-lg-max) {
    --section-margin: 3rem;
    --section-margin-s: 3rem;
  }
}

.section-margin {
  margin-top: var(--section-margin);
}

.homepage-section {
  a {
    font-weight: var(--font-bold);
  }

  &__header {
    font-size: 2.4rem;
    font-weight: var(--font-bold);
    line-height: 2.8rem;
    margin-bottom: var(--spacing-l);
    text-align: left;

    &--no-margin {
      margin-bottom: 0;
    }

    @media (--screen-lg-max) {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    @media (--screen-md-max) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &__sub {
    margin: var(--spacing) 0;
    text-align: left;
  }
}

.banner {
  height: var(--height-100);
  min-height: 800px;
  overflow: hidden;
  position: relative;

  @media (--screen-sm-max) {
    height: 280px;
    min-height: auto;
  }
}

.banner-slider {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;

  &__overlay {
    background-color: rgba(0, 0, 0, 30%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    @media (--screen-md-max) {
      background-color: rgba(0, 0, 0, 70%);
    }
  }

  .inner_image {
    height: 100%;
    width: 100vw;
  }
}

.banner-content {
  color: white;
  position: relative;
  z-index: 1;

  &__wrapper {
    padding-bottom: 120px;

    @media (--screen-md-max) {
      padding-bottom: 0;
    }
  }

  &__title {
    font-size: 3.75em;
    font-weight: var(--font-bold);
    line-height: 1.17;
    margin: 0;

    @media (--screen-md-max) {
      font-size: 1.5em;
    }
  }

  &__description {
    font-size: 1.125em;
    font-weight: var(--font-bold);
    line-height: 2;
    margin-bottom: 40px;
    margin-top: 20px;
    opacity: 0.7;

    @media (--screen-md-max) {
      font-size: 0.75em;
      font-weight: var(--font-medium);
      line-height: 1.5;
      opacity: 1;
    }
  }

  &__link {
    font-size: 1.125em;
    padding: 14px 30px;

    @media (--screen-md-max) {
      font-size: 0.875em;
    }
  }
}

.js-scroll {
  opacity: 0;
}

.js-scroll.scrolled {
  animation: fadeEffect 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(2rem);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
