.page-preview {
  --bn-mustard-text: #5d502b;
  --bn-light-blue-text: #112748;
  --bn-pistachio-text: #153e34;
  --bn-mustard-text-filter: invert(32%) sepia(11%) saturate(1677%)
    hue-rotate(7deg) brightness(90%) contrast(90%);
  --bn-light-blue-text-filter: invert(9%) sepia(35%) saturate(3604%)
    hue-rotate(202deg) brightness(93%) contrast(91%);
  --bn-pistachio-text-filter: invert(16%) sepia(44%) saturate(633%)
    hue-rotate(115deg) brightness(97%) contrast(90%);

  &__wrapper {
    display: grid;
    gap: var(--spacing-xxl);
    grid-template-columns: repeat(3, 1fr);
    margin: var(--spacing-xl) 0;

    @media (--screen-lg-max) {
      gap: var(--spacing-xl);
      grid-template-columns: repeat(2, 1fr);
      margin: var(--spacing-l) 0;
    }

    @media (--screen-sm-max) {
      gap: var(--spacing-xl);
      grid-template-columns: repeat(1, 1fr);
      margin: var(--spacing) 0;
    }
  }

  &__page {
    color: var(--bn-text);
    justify-content: space-between;

    &:hover {
      color: var(--bn-default-bg);
      text-decoration: none !important;
    }
  }

  &__main {
    font-weight: var(--font-regular);
  }

  &__page,
  &__main {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
  }

  &__bang {
    border-bottom: solid var(--bang-height) var(--bn-orange);
    border-radius: 0.5rem;
    width: 2rem;
  }

  &__title {
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
  }

  &__footer {
    align-items: center;
    display: flex;
    font-weight: var(--font-semi-bold);
    gap: var(--spacing);
    justify-content: flex-start;
  }

  &__label {
    align-items: center;
    display: flex;
    gap: var(--spacing-xxxs);

    &--career-path-guides {
      background-color: rgba(var(--bn-mustard-rgb), 0.4);
      color: var(--bn-mustard-text);

      .page-preview__label-icon {
        filter: var(--bn-mustard-text-filter);
      }
    }

    &--learning {
      background-color: rgba(var(--bn-light-blue-rgb), 0.4);
      color: var(--bn-light-blue-text);
    }

    &--career-advice {
      background-color: rgba(var(--bn-pistachio-rgb), 0.4);
      color: var(--bn-pistachio-text);
    }
  }

  &__timestamp {
    color: var(--bn-text-muted);
    font-size: var(--font-xs);
  }

  &__readtime-icon {
    filter: var(--bn-text-filter);
    margin-right: var(--spacing-xxxs);
  }

  &__readtime {
    align-items: center;
    color: var(--bn-text-muted);
    display: flex;
    font-size: var(--font-xs);
  }
}
