.member-journey {
  margin: var(--section-margin) auto -2rem;

  &__container {
    text-align: justify;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (--screen-md-max) {
      grid-template-columns: 1fr;
    }
  }

  &__swiper-container {
    max-height: 36.375rem;
    padding: var(--spacing) var(--spacing-xxs);
    position: relative;

    @media (--screen-sm-max) {
      height: 44.375rem;
    }
  }

  #member_journey {
    overflow: visible;
    transform: scale(0.8);
  }

  #member-journey__image {
    @media (--screen-md-max) {
      display: none;
    }
  }

  &__card-img {
    border-bottom: 1px solid var(--border-grey-1);
    border-top: 1px solid var(--border-grey-1);
    height: 11.25rem;
    margin: var(--spacing) -2rem;
    object-fit: contain;
    padding: var(--spacing) 0;
    width: auto;

    @media (--screen-md-max) {
      height: 8.125rem;
      padding: var(--spacing-xs) 0;
    }
  }

  &__header {
    font-size: 2.5em;
    font-weight: var(--font-bold);

    @media (--screen-md-max) {
      font-size: 1.5em;
    }
  }

  &__card-header {
    font-size: var(--font-xlg);
    line-height: 1.9rem;
  }

  &__card-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .bn-card__text {
    @media (--screen-lg-max) {
      margin-bottom: var(--spacing-xxs);
    }
  }

  .swiper-pagination-bullets {
    right: 0 !important;

    @media (--screen-md-max) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background: var(--bn-orange);
    height: 15px;
    margin: 16px 0 !important;
    width: 15px;
  }

  &__slide {
    align-items: center;
    display: flex;
    width: 90%;

    @media (--screen-md-max) {
      align-items: flex-start;
      width: 100%;
    }
  }

  .stop {
    &.up {
      animation: 1s stopMoveUp ease forwards;
    }

    &.down {
      animation: 1s stopMoveDown ease forwards;
    }
  }

  .stopBase {
    transform-box: fill-box;
    transform-origin: 50% 100%;

    &.up {
      animation: 1s baseGrow ease forwards;
    }

    &.down {
      animation: 1s baseShrink ease forwards;
    }
  }

  .icon {
    opacity: 0;
    pointer-events: none;

    .innerIcon {
      transform-origin: center;
    }

    &.up {
      animation: 0.8s 0.2s iconMoveUp ease forwards;

      .innerIcon {
        animation: 0.8s 0.2s scaleUp ease forwards;
      }
    }

    &.down {
      animation: 0.4s iconMoveDown ease forwards;

      .innerIcon {
        animation: 0.4s scaleDown ease forwards;
      }
    }
  }

  a {
    padding-top: var(--spacing);
  }

  #stop0,
  #stop1,
  #stop2,
  #stop3,
  #stop4 {
    cursor: pointer;
  }

  @keyframes iconMoveUp {
    0% {
      opacity: 0;
      transform: translate(0, -70px);
    }

    100% {
      opacity: 1;
      transform: translate(0, -110px);
    }
  }

  @keyframes iconMoveDown {
    0% {
      opacity: 1;
      transform: translate(0, -110px);
    }

    100% {
      opacity: 0;
      transform: translate(0, -70px);
    }
  }

  @keyframes scaleUp {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.3);
    }
  }

  @keyframes scaleDown {
    0% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes stopMoveUp {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-30px);
    }
  }

  @keyframes stopMoveDown {
    0% {
      transform: translateY(-30px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes baseGrow {
    0% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 2);
    }
  }

  @keyframes baseShrink {
    0% {
      transform: scale(1, 2);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @media (--screen-lg-max) {
    margin: var(--section-margin) auto 0;
  }

  @media (--screen-md-max) {
    margin-bottom: 0;
  }
}
