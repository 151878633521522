.employers {
  &__header {
    margin-bottom: -1rem;

    @media (--screen-md-max) {
      margin-bottom: -3rem;
    }
  }

  .swiper-container {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .swiper-wrapper {
    min-height: 150px;
    padding-bottom: 25px;

    @media (--screen-sm-min) {
      min-height: 250px;
    }

    @media (--screen-md-min) {
      min-height: 295px;
    }
  }

  .swiper-slide {
    background-origin: content-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100px;
    margin-top: 0 !important;
    padding: 15px;

    a {
      display: inline-block;
      height: 100%;
      width: 100%;
    }
  }

  .swiper-pagination-bullets {
    margin-bottom: -15px;
  }

  .swiper-pagination-bullet {
    margin: 0 10px !important;
  }
}
