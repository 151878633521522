.hero {
  background: #fdeadc;
  height: var(--height-100);
  min-height: 800px;
  position: relative;

  &__wrapper {
    align-items: center;
    display: grid;
    grid-gap: var(--spacing);
    grid-template-columns: repeat(5, 1fr);
    height: inherit;
    min-height: inherit;

    @media (--screen-md-max) {
      grid-gap: var(--spacing-xl);
      grid-template-rows: 200px 1fr;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    grid-column: span 3;
    justify-content: center;
    z-index: 2;

    @media (--screen-md-max) {
      grid-column: span 5;
      order: 2;
    }
  }

  &__title {
    font-size: 3rem;
    font-weight: var(--font-bold);
  }

  &__subtitle {
    font-size: var(--font-sm);
  }

  &__img-wrapper {
    grid-column: span 2;
    transform: scale(1.4) translate(-1rem, -1rem);
    z-index: 0;

    @media (--screen-md-max) {
      align-self: center;
      grid-column: span 5;
      order: 1;
      transform: scale(1.2) translate(0, 0);
      transform: scale(1);
    }
  }

  &__img {
    height: auto;
    object-fit: contain;
    width: 100%;

    @media (--screen-lg) {
      margin-left: 2rem;
      transform: scale(0.9);
    }

    @media (--screen-md-max) {
      height: 200px;
      margin-left: 0;
      transform: scale(1);
    }
  }

  &__main,
  &__img-wrapper {
    margin-top: -6rem;

    @media (--screen-md-max) {
      align-self: baseline;
      margin-top: 0;
    }
  }

  &__ctas-label {
    margin: var(--spacing) 0;
  }

  &__ctas {
    display: flex;
    gap: var(--spacing);
    justify-content: flex-start;
    padding: var(--spacing) 0;

    > a {
      animation: fadeEffect 1s;
      animation-fill-mode: forwards;
      opacity: 0;

      &:nth-child(2) {
        animation-delay: 0.1s;
      }
    }

    @media (--screen-sm) {
      flex-direction: column;
    }
  }

  &__charity {
    align-items: center;
    border-radius: var(--box-border-radius-s);
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    padding: var(--spacing-m) 0;
    transition: all 0.4s ease-out;
    width: 560px;

    a {
      color: var(--black);
      font-weight: var(--font-medium);
      text-decoration: underline;
    }

    @media (--screen-sm) {
      flex-direction: column;
      margin-right: 0;
    }

    @media (--screen-sm) {
      width: inherit;
    }
  }

  &__charity-counter {
    width: 12rem;

    @media (--screen-sm-min) {
      flex-basis: 120px;
      flex-shrink: 0;
    }
  }

  &__charity-counter-number {
    display: flex;
    font-size: 2.7rem;
    font-weight: var(--font-medium);
    justify-content: center;
    line-height: 2.8rem;
  }

  &__charity-counter-legend {
    font-size: var(--font-sm);
    font-weight: var(--font-bold);
    text-align: center;
  }

  &__scroll-arrow {
    bottom: 4rem;
    left: 50%;
    position: absolute;
    transition: all 0.2s ease-out;
    z-index: 10;

    &:hover {
      transform: scale(1.3);
    }

    img {
      object-fit: contain;
    }

    @media (--screen-lg) {
      display: none;
    }
  }

  @media (--screen-md-max) {
    background-position: center;
  }

  @media (--screen-md-max) {
    height: initial;
    min-height: initial;
    padding: var(--spacing-xl) 0;
  }
}
